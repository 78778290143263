export const DISALLOW_GOOGLE_INDEXING = process.env.NEXT_PUBLIC_DISALLOW_GOOGLE_INDEXING;
export const PRISMIC_API_ENDPOINT = process.env.NEXT_PUBLIC_PRISMIC_API_ENDPOINT || '';
export const DEFAULT_PAGE_TITLE = process.env.NEXT_PUBLIC_DEFAULT_PAGE_TITLE || 'Digital House';
export const PUBLIC_DEFAULT_LOCALE = process.env.NEXT_PUBLIC_DEFAULT_LOCALE;
export const PAGE_SERVER_SIDE_CACHE_CONTROL = process.env.NEXT_PUBLIC_PAGE_SERVER_SIDE_CACHE_CONTROL;
export const SPANISH_LOCALE = 'es';
export const VERTICAL_PROGRAMMING = 'programming';
export const HOST = 'www.digitalhouse.com';
export const CHECKOUT_UTM_SOURCE = 'web';
export const SUPPORTED_COUNTRIES = ['CL', 'PE', 'UY', 'MX', 'AR', 'CO', 'BR'];
export const CHECKOUT_NEW_URL = process.env.NEXT_PUBLIC_CHECKOUT_NEW_URL || 'https://checkout.digitalhouse.com';

export const TABOOLA_ADS_KEY = {
  es: process.env.NEXT_PUBLIC_TABOOLA_ADS_KEY_LAT,
  'es-AR': process.env.NEXT_PUBLIC_TABOOLA_ADS_KEY_AR,
  'pt-BR': process.env.NEXT_PUBLIC_TABOOLA_ADS_KEY_BR,
  'es-MX': process.env.NEXT_PUBLIC_TABOOLA_ADS_KEY_MX,
  'es-CO': process.env.NEXT_PUBLIC_TABOOLA_ADS_KEY_CO,
  'es-CL': process.env.NEXT_PUBLIC_TABOOLA_ADS_KEY_CL,
};

export enum BusinessUnits {
  career = 1,
  couse = 2
}

export enum NextStepFlow {
  moreInfo = 'MoreInfoFlow',
  checkout = 'CheckoutFlow',
  advice = 'AdviceFlow',
}

export enum TrackEvents {
  buttonClicked = 'Button Clicked',
  productApplicationSubmitted = 'Product Application Submitted',
  productApplicationClicked = 'Product Application Clicked',
  newsletterFormSubmitted = 'Newsletter Form Submitted',
  brochureDownloaded = 'Brochure Downloaded',
  brochureDownloadedClicked = 'Brochure Download Clicked',
  whatsappClicked = 'Whatsapp Clicked'
}

export const linkedinConversions = {
  'es-AR': {
    [TrackEvents.brochureDownloaded]: process.env.NEXT_PUBLIC_LKIN_BROCHURE_DOWNLOAD_AR,
    [TrackEvents.productApplicationSubmitted]: process.env.NEXT_PUBLIC_LKIN_PRODUCT_APPLICATION_SUBMITTED_AR,
    [TrackEvents.newsletterFormSubmitted]: process.env.NEXT_PUBLIC_LKIN_NEWSLETTER_FORM_SUBMITTED_AR,
  },
  'pt-BR': {
    [TrackEvents.brochureDownloaded]: process.env.NEXT_PUBLIC_LKIN_BROCHURE_DOWNLOAD_BR,
    [TrackEvents.productApplicationSubmitted]: process.env.NEXT_PUBLIC_LKIN_PRODUCT_APPLICATION_SUBMITTED_BR,
    [TrackEvents.newsletterFormSubmitted]: process.env.NEXT_PUBLIC_LKIN_NEWSLETTER_FORM_SUBMITTED_BR,
  },
  'es-CO': {
    [TrackEvents.brochureDownloaded]: process.env.NEXT_PUBLIC_LKIN_BROCHURE_DOWNLOAD_CO,
    [TrackEvents.productApplicationSubmitted]: process.env.NEXT_PUBLIC_LKIN_PRODUCT_APPLICATION_SUBMITTED_CO,
    [TrackEvents.newsletterFormSubmitted]: process.env.NEXT_PUBLIC_LKIN_NEWSLETTER_FORM_SUBMITTED_CO,
  },
  'es-CL': {
    [TrackEvents.brochureDownloaded]: process.env.NEXT_PUBLIC_LKIN_BROCHURE_DOWNLOAD_CL,
    [TrackEvents.productApplicationSubmitted]: process.env.NEXT_PUBLIC_LKIN_PRODUCT_APPLICATION_SUBMITTED_CL,
    [TrackEvents.newsletterFormSubmitted]: process.env.NEXT_PUBLIC_LKIN_NEWSLETTER_FORM_SUBMITTED_CL,
  },
  'es-MX': {
    [TrackEvents.brochureDownloaded]: process.env.NEXT_PUBLIC_LKIN_BROCHURE_DOWNLOAD_MX,
    [TrackEvents.productApplicationSubmitted]: process.env.NEXT_PUBLIC_LKIN_PRODUCT_APPLICATION_SUBMITTED_MX,
    [TrackEvents.newsletterFormSubmitted]: process.env.NEXT_PUBLIC_LKIN_NEWSLETTER_FORM_SUBMITTED_MX,
  },
  es: {
    [TrackEvents.brochureDownloaded]: process.env.NEXT_PUBLIC_LKIN_BROCHURE_DOWNLOAD_LAT,
    [TrackEvents.productApplicationSubmitted]: process.env.NEXT_PUBLIC_LKIN_PRODUCT_APPLICATION_SUBMITTED_LAT,
    [TrackEvents.newsletterFormSubmitted]: process.env.NEXT_PUBLIC_LKIN_NEWSLETTER_FORM_SUBMITTED_LAT,
  },
};

export enum DocumentType {
  clusterPage = 'clusterpage',
  coursePage = 'coursepage',
  footer = 'footer',
  header = 'header',
  homePage = 'homepage',
  orderSummaryPage = 'ordersummarypage',
  page = 'page',
  products = 'products',
  summaryPage = 'summarypage',
  thankYouPage = 'thankyoupage',
  verticalPage = 'verticalpage',
}
